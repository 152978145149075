import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Hero from '../components/Hero'

class Speaking extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const siteKeywords = get(
      this,
      'props.data.site.siteMetadata.siteKeywords'
    )
    const posts = get(this, 'props.data.allSpeakingJson.edges')
    const talks = get(this, 'props.data.allTalksJson.edges')

    const siteUrl = get(
      this,
      'props.data.site.siteMetadata.siteUrl'
    )
    const image = siteUrl + get(this, 'props.data.headerImage.sizes.src');

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            //html tags
            { name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords},
            { itemprop: 'name', content: siteTitle },
            { itemprop: 'description', content: siteDescription},
            { itemprop: 'image', content: image},
            //facebook tags
            {property: 'og:title', content: siteTitle},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: image},
            {property: 'og:url', content: siteUrl},
            {property: 'og:description', content: siteDescription},
            //twitter tags
            {property: 'twitter:card', content: 'twitter:card'},
            {property: 'twitter:title', content: siteTitle},
            {property: 'twitter:creator', content: '@likeOMGitsFEDAY'},
            {property: 'twitter:image', content: image},
            {property: 'twitter:url', content: siteUrl},
            {property: 'twitter:description', content: siteDescription}
          ]}
          title={siteTitle}
        />
        <Hero headerImage={this.props.data.headerImage}
          heading="Speaking Engagements"
          text="" />

        <div style={{backgroundColor:'#fff', color: '#696969'}}>
          <div className="container">
            <h1>Upcoming & Past Appearances</h1>
            Total speaking engagements: {posts.length} Keynotes: {posts.filter((talk) => talk.node.keynote).length}
            <FlexGrid>
              {posts.reverse().map(({ node }) => {
                let image =  require('../assets/img/speaking/default.jpeg');
                let isKeynoteClass = node.keynote ? 'keynote' : '';
                let isPandemicClass = node.pandemicAltered ? 'pandemic' : '';
                let className = isKeynoteClass + ' ' + isPandemicClass;
                if(node.image) {
                  let customImage = require('../assets/img/speaking/' + node.image);
                  if(customImage) {
                    image = customImage;
                  }
                }
                return (
                  <Conference key={node.date} className={className}>
                    <span className="star"></span>
                    <p className="date">{ node.date }</p>
                    <a className="image-container" target="_blank" href={ node.url} aria-label={"read more about " + node.conference}
                    style={{ backgroundImage:"url("+image+")", backgroundSize:"cover"}} rel="noreferrer">
                    </a>
                    <h2><a target="_blank" href={ node.url } aria-label={"read more about " + node.conference}  rel="noreferrer">{ node.conference}</a></h2>
                    <p className="location">{ node.location }</p>
                    <ul>
                      {node.talks.map((talk) => {
                        if(talk.video) {
                          return (
                            <li key={talk.title}>{talk.title} (<a target="_blank" rel="noopener" href={ talk.video } aria-label={"watch " + talk.title}>video</a>)</li>
                          )
                        }
                        else {
                          return (
                            <li key={talk.title}> {talk.title}</li>
                          )
                        }

                      })}
                    </ul>
                  </Conference>
                )
              })}
            </FlexGrid>
            <h2>Talks</h2>
            <FlexGrid>
              {talks.map(({ node }) => {
                return (
                  <Talk key={node.title}>
                    <h3>{node.title}</h3>
                    <p>{node.abstract}</p>
                    <p><a target="_blank" rel="noopener" href={node.slides} aria-label={"view " + node.title + " slides"}>Slides</a></p>
                  </Talk>
                )
              })}
            </FlexGrid>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Speaking

const FlexGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
`

const Talk = styled.div`
  a {
    color: #1A7B8E;
  }
`

const Conference = styled.div`
  @media(max-width:425px) {
      width: 100%;
  }
  padding: 10px 30px;
  border: solid 1px #ccc;
  margin: 20px 0px;
  width: calc( (100% / 3) - 60px );
  position: relative;
  h2 {
    font-size: 20px;
    margin: 10px 0px 10px 0px;
    text-transform: capitalize;
    letter-spacing: -.05em;
    a {
      font-weight: bold;
    }
  }
  a {
    box-shadow: none;
    color: #1A7B8E;
  }
  .image-container {
    height: 150px;
    display: block;
    img {
      max-width: 100%;
    }
  }

  p {
    margin: 0px 0px 10px 0px;
    font-size: 16px;
  }
  ul {
    margin: 0px;
    padding: 0px;
    li {
      font-size: 10px;
      margin: 0px;
      padding: 0px;
    }
  }

  .date {
    text-transform: uppercase;
    font-size: 10px;
  }
  .location {
    display: block;
    clear: both;
  }
`

export const pageQuery = graphql`
  query {
    headerImage: imageSharp(original: {src: { regex: "/stretchconf2018/" }} ) {
      sizes(maxWidth: 1240 ) {
        ...GatsbyImageSharpSizes
      }
    }
    allSpeakingJson {
      edges {
        node {
          conference
          year
          url
          date
          location
          image
          keynote
          pandemicAltered
          talks {
            title
            video
          }
        }
      }
    }
    allTalksJson {
      edges {
        node {
          title
          abstract
          slides
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteKeywords
        siteUrl
      }
    }
  }
`
